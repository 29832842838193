<template>
  <div class="root">
    <div class="toolbar">
      {{ title }}
    </div>
    <div class="container">
      <video :src="video" controls autoplay />
      <div class="video-desc">
        {{ videoDesc }}
      </div>
    </div>
    <div class="download-bar">
      <div class="logo-bar">
        <img class="logo" src="@/assets/logo.png" alt="logo"/>
        <div class="app-name">智安康</div>
      </div>
      <div class="button" @click="onDownloadClick">下载</div>
    </div>
  </div>
</template>

<script>

import { ref } from 'vue'
import { useRoute } from 'vue-router'

export default {
  name: 'video',
  setup () {
    const route = useRoute()
    console.log('zzz', route.query)

    const title = ref('健康资讯')
    const video = ref('https://stream7.iqilu.com/10339/upload_transcode/202002/18/20200218025702PSiVKDB5ap.mp4')
    const videoDesc = ref('这是一条很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长很长的视频描述信息')

    const onDownloadClick = () => {
      window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=com.greatech.android'
    }

    return {
      title,
      video,
      videoDesc,
      onDownloadClick
    }
  }
}
</script>

<style lang="scss" scoped>

.root {
  width: 100%;
  height: 100%;

  .toolbar {
    width: 100%;
    height: 56px;
    background: black;
    font-size: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: white;
  }

  .container {
    width: 100%;
    height: calc(100% - 56px);
    padding: 8px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: black;

    video {
      width: 100%;
    }

    .video-desc {
      width: calc(100% - 32px);
      color: white;
      bottom: 64px;
      word-break: break-word;
      position: absolute;
      display: flex;
      text-align: left;
    }
  }

  .download-bar {
    width: 100%;
    height: 48px;
    position: absolute;
    background: white;
    border-top: 1px solid #F4F5F9;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 16px;

    .logo-bar {
      display: flex;
      align-items: center;

      .logo {
        width: 36px;
        height: 36px;
      }

      .app-name {
        margin-left: 8px;
      }
    }

    .button {
      min-width: 60px;
      height: 30px;
      background: #227cff;
      border-radius: 4px;

      font-weight: 500;
      color: white;

      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 16px;
    }
  }
}
</style>
